@font-face {
  font-family: "Montserrat";
  src: url("./fonts/MontserratDavyd/Montserrat/Montserrat-Medium.ttf")
    format("ttf");
  font-weight: medium;
  font-style: normal;
}
html,
body {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
