main {
  flex-grow: 1;
  flex-shrink: 1;
}
.AppMAIN {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.notFoundPage {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
}
.notfoundBox {
  display: block;
  text-align: center;
  width: fit-content;
  font-size: 18px;
}

/* На деяких сторінках, стилі наслідуються від попередньої сторінки так як мають однакове оформлення сторінки та елементів на ній!!! */

/* =================================header====================================== */

.headerStyle {
  background-color: rgb(103, 119, 135);
}
.navbarLogoBrand {
  align-items: center;
}
.wrapperLogoText {
  display: flex;
  margin-left: 10px;
}
.logoTextValue {
  display: flex;
  white-space: wrap;
  width: 120px;
  font-size: 16px;
  line-height: 1.2;
}
.collapseSettings {
  display: flex;
}
.header_button {
  display: flex;
  width: 100%;
}
.headerNavbutton {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .headerLogoStyle {
    width: 70px;
    height: 40px;
  }
  .logoTextValue {
    font-size: 12px;
  }
}

/* ==================================header випадаючі списки та кнопки===================== */

.headerLink {
  padding: 5px;
  white-space: nowrap;
  text-decoration: none;
  color: rgb(255, 255, 255, 0.55) !important;
}
.dropdown-toggle.nav-link {
  color: rgb(255, 255, 255, 0.8) !important;
}
.dropdown-toggle.show.nav-link {
  color: rgb(255, 255, 255, 1) !important;
}
.dropdown-toggle.nav-link:hover {
  color: rgb(91, 185, 187) !important;
}
.dropdown-toggle.nav-link:focus {
  color: rgb(91, 185, 187) !important;
}
.dropdown-menu.show {
  display: flex !important;
  flex-direction: column !important;
  /* padding: 5px 10px 5px 10px!important; */
}
.headerLinkDrop {
  color: rgb(103, 119, 135) !important;
  padding-left: 15px;
  padding-right: 15px;
}
.headerLinkDrop:hover {
  background-color: rgb(228, 226, 226) !important;
}
.activeLinkDropHeader {
  padding: 5px;
  white-space: nowrap;
  text-decoration: none;
  color: rgb(103, 119, 135) !important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(228, 226, 226) !important;
}
.activeLinkNoDropHeader {
  padding: 5px;
  white-space: nowrap;
  text-decoration: none;
  color: rgb(91, 185, 187) !important;
}
#ActiveProdId {
  color: rgb(91, 185, 187) !important;
}
.btnInBurger {
  display: flex;
  width: 50%;
  justify-content: space-between;
  padding-left: 20px;
}
.headerLinkNoDrop {
  color: rgb(255, 255, 255, 0.8) !important;
}
.headerLinkNoDrop:hover {
  color: rgb(91, 185, 187) !important;
}
.callBackBtn {
  border: 1px solid transparent;
  background-color: #004c5d;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}
.callBackBtn:hover {
  background-color: rgb(91, 185, 187);
}

@media screen and (max-width: 992px) {
  .headerNavbutton {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    align-items: start;
    margin-top: 20px;
  }
  .btnInBurger {
    display: flex;
    flex-direction: column;
  }
  .callBackBtn {
    width: fit-content;
    margin-left: auto;
  }
  .headerLinkNoDrop {
    padding: 3px;
    padding-left: 0;
  }
  .dropdown-toggle.nav-link {
    padding: 3px !important;
    padding-left: 0 !important;
  }
  .headerLinkDrop {
    font-size: 14px;
    white-space: wrap;
  }
}
@media screen and (max-width: 576px) {
}

/* ===============================footer=============================== */

.footerStyle {
  background-color: rgb(103, 119, 135);
  padding: 50px 0 25px 0;
  color: white;
}
.wrapperLogoTextFooter {
  display: flex;
}
.logoTextValueFooter {
  display: flex;
  white-space: wrap;
  width: 120px;
  font-size: 14px;
  line-height: 1.2;
  color: white;
  margin-top: 10px;
}
.colStyle {
  display: flex;
  flex-direction: column;
}
.product {
  color: white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.product:hover {
  cursor: pointer;
  color: rgb(91, 185, 187);
}
.activeLinkFooter {
  color: rgb(91, 185, 187) !important;
}
.service {
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}
.service:hover {
  cursor: pointer;
  color: rgb(91, 185, 187);
}
.kaiInfo {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}
.kaiInfo:hover {
  cursor: pointer;
  color: rgb(91, 185, 187);
}
.mailPhone {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: auto;
}
.mailFooter {
  margin-left: auto;
}
.iconSocial {
  margin-left: auto;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}
.iconFacebook {
  margin-right: 20px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.iconFacebook:hover {
  background-color: rgb(91, 185, 187);
  cursor: pointer;
}
.iconYoutube {
  border-radius: 50%;
}
.iconYoutube:hover {
  background-color: rgb(91, 185, 187);
  cursor: pointer;
}
.iconIso {
  margin-left: auto;
}
.isoLink {
  margin-left: auto;
}
/* .corporation {
  min-width: 100% !important;
  text-align: center;
  margin-top: 50px !important;
} */
.testT {
  text-align: center;
}
@media screen and (max-width: 992px) {
  .productMargin {
    margin-top: 30px;
  }
  .rightStyle {
    margin-left: auto;
  }
  .mailPhone {
    margin-left: 0;
  }
  .mailFooter {
    margin-left: 0;
  }
  .iconSocial {
    margin-left: 0;
  }
  .iconIso {
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .logoCol {
    display: flex !important;
    align-items: center !important;
  }
  .wrapperLogoTextFooter {
    display: block;
    width: fit-content;
    padding-left: 20px;
  }
  .fatButtonLink {
    margin-top: 30px !important;
  }
  .rightStyle {
    margin-top: 30px !important;
  }
  .productCol {
    margin-top: 30px !important;
  }
}

/* ================================HomePage============================ */

.homePageDiv {
  position: relative;
}
/* .arrowNo {
  display: none;
} */
.upArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  height: 50px;
  padding: 10px;
  padding-top: 20px;
  background-color: #677787;
  border: 1px solid white;
  color: white;
  font-size: 50px;
  position: fixed;
  top: 80%;
  right: 15px;
  cursor: pointer;
}
.homeFluid {
  padding: 0 !important;
}
.brandpage {
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.7s ease-in, opacity 0.7s ease-in;
}
.brandpage.show {
  height: auto;
  opacity: 1;
}

/* ======products================== */

.productsNameH4 {
  color: rgb(117, 119, 135);
}
.leftBox {
  position: relative;
  width: fit-content;
}
.linkProducts {
  text-decoration: none;
  padding: 5px 10px 5px 10px;
  background-color: #004c5d;
  height: fit-content;
  color: white;
  border-radius: 25px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.2s ease-in-out;
}
.linkProducts:hover {
  background-color: rgb(91, 185, 187);
}
.rightBox {
  position: relative;
  width: fit-content;
  margin-left: auto;
}

.leftBoxImg {
  width: 100%;
}
.rightBoxImg {
  width: 100%;
}

/* ======AboutUs================== */

.aboutUsStyle {
  color: #677787;
}

.wrapperHand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.styleNumber {
  color: #677787;
  font-size: 24px;
  font-weight: bold;
}
.titlePartners {
  color: #677787;
  font-size: 18px;
}

/* ======Services================== */

.bgStyleFluid {
  background-color: #f5f5f5;
}
.colServiceStyle {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding: 25px;
}
.wrapperServiceValue {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  padding-bottom: 30px;
  border: 2px solid rgb(91, 185, 187);
  position: relative;
}
.numberBoxServices {
  display: flex;
  justify-content: center;
  text-align: center;
  /* align-items: center; */
  font-size: 90px;
  font-weight: bold;
  color: rgb(91, 185, 187);
  padding: 10px;
  background-color: #f5f5f5;
  position: absolute;
  top: -70px;
  left: -40px;
}
.nameBoxService {
  font-size: 20px;
  font-weight: 500;
  color: #677787;
  margin-bottom: 20px;
}
.textService {
  font-size: 16px;
  color: #677787;
  margin-bottom: auto;
}
.btnAboutNumberBox {
  padding: 5px 10px 5px 10px;
  background-color: #004c5d;
  width: fit-content;
  border-radius: 25px;
  margin-top: 15px;
  margin-left: auto;
  color: white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.btnAboutNumberBox:hover {
  background-color: rgb(91, 185, 187);
  cursor: pointer;
}

/* ======News================== */

.News {
  display: flex;
  flex-direction: column;
}
.newsLinkStyle {
  text-decoration: none;
  font-size: 18px;
  color: #677787;
  width: fit-content;
}
.newsLinkStyle:hover {
  text-decoration: underline;
}

/* ======Partners================== */

.Partners {
  display: flex;
  flex-direction: column;
}
.wrapperPartnerIcons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .linkProducts {
    bottom: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 992px) {
  .linkProducts {
    font-size: 14px;
    bottom: 5px;
    right: 2px;
    padding: 5px;
  }
}
@media screen and (max-width: 768px) {
  .colProducts {
    margin-bottom: 10px;
  }
  .linkProducts {
    font-size: 16px;
    bottom: 10px;
    right: 10px;
    padding: 5px 10px 5px 10px;
  }
}
@media screen and (max-width: 500px) {
  .linkProducts {
    font-size: 14px;
    bottom: 5px;
    right: 3px;
    padding: 3px;
  }
}
@media screen and (max-width: 400px) {
  .wrapperServiceValue {
    padding: 20px;
  }
}

/* ================================KdocumentPage============================ */

.aboutElectroOb {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.colProductPage {
  padding: 50px;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.linkDocProduct {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #677787;
  margin-bottom: 15px;
  transition: all 0.2s ease-in-out;
}
.linkDocProduct:hover {
  color: rgb(91, 185, 187);
}
.prodBoxKdoc {
  display: flex;
}
.leftElectroImg {
  width: 232px;
  height: 232px;
  display: block;
}
.textElectroOb {
  font-size: 16px;
  color: #677787;
}

@media screen and (max-width: 1200px) {
  .colProductPage {
    padding: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 992px) {
  .prodBoxKdoc {
    display: block;
  }
  .leftElectroImg {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

/* ================================SoftwareServicesPage============================ */

.SoftwareName {
  font-size: 22px;
  color: #677787;
}
.SoftwareFluidStyle {
  background-color: #f5f5f5;
}

/* =================AnimationText========= */

.typewriter {
  overflow: hidden; /* Щоб текст не був видимий до початку анімації */
  white-space: nowrap; /* Щоб текст не переносився на новий рядок */
  border-right: 2px solid transparent; /* "машинка для друку" (смуга) */
  /* Анімація печатної машинки */
  animation: typing 5s steps(60, end) 1;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.softwareH5 {
  color: #677787;
  font-size: 20px;
  text-align: center;
}
.softwareNameTextH5 {
  color: #677787;
  font-size: 20px;
}
.colStyleSoftware {
  margin-bottom: 20px !important;
}
.wrapperSoftwareText {
  display: flex;
  flex-direction: column;
}
.softwareText {
  color: #677787;
}
.softwareImgText {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SecurityStyleImage {
  width: 70px;
}
.textImgSecurity {
  color: #677787;
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
}
@media screen and (max-width: 450px) {
  .typewriter {
    width: 270px;
    white-space: wrap;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

/* ================================CreateModelsPage============================ */

.colModelsCtyle {
  padding-top: 60px;
}
.wrapperCreateModelsImgText {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.imgWorkManStyle {
  width: 100%;
}
.ulList {
  padding-left: 10px;
}
.ulTitleStyle {
  color: #677787;
  font-size: 22px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.liRow {
  color: #677787;
  margin-bottom: 10px;
}
.liRow::marker {
  color: rgb(91, 185, 187);
}
.colTextStyleModels {
  display: flex;
  flex-direction: column;
  color: #677787;
}

/* ================================ConsaltingPage============================ */

/* Стилі наслідуються від попередньої сторінки!!! */

.liRowConsalt {
  color: #677787;
  margin-bottom: 2px;
}
.liRowConsalt::marker {
  color: rgb(91, 185, 187);
}
.textStyleConsalt {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/* ================================ProjectSearchPage============================ */

/* Стилі наслідуються від сторінки ConsaltingPage!!! */

/* ================================SecurityIntelectPage=================*/

/* Частково стилі наслідуються від сторінки ConsaltingPage!!! */
.ulListSecurity {
  list-style-type: none;
  color: #677787;
  font-size: 20px;
  line-height: 2;
}
.liRowSecurity {
  /* line-height: 2; */
}
.liRowSecurityName {
  color: rgb(91, 185, 187);
  font-weight: 500;
}

@media screen and (max-width: 450px) {
  .ulListSecurity {
    padding-left: 10px;
    font-size: 16px;
  }
}

/* ================================PartnersPage============================ */

.wrapperImgPartners {
  width: fit-content;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}

/* ================================AboutUsPage============================ */

/* ====================Є стилі та елементи головної сторінки лічильник і тд.========= */
.AboutPageStyleDiv {
  display: flex;
  flex-direction: column;
  color: #677787;
}
.nameText {
  font-size: 22px;
  text-align: center;
  margin-bottom: 25px;
}
.textAboutUsStyle {
  margin-bottom: 15px;
}
.nameTextLink {
  font-size: 22px;
  margin-bottom: 15px;
}
.LinkStyleAboutUs {
  width: fit-content;
  font-size: 16px;
  color: #004c5d;
  font-weight: 400;
  transition: all 0.2s ease-in;
}
.LinkStyleAboutUs:hover {
  color: rgb(91, 185, 187);
}

/* ================================AboutUsPage ElectroOborot========================= */

.imgWrappGirl {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.imgOnPage {
  width: 100%;
}
.nameTextGirl {
  margin-bottom: 20px;
  font-size: 20px;
}
.btnWrapDocOborot {
  display: flex;
  flex-wrap: wrap;
}
.btnGirlDocOborot {
  text-decoration: none;
  padding: 5px 10px 5px 10px;
  background-color: #004c5d;
  color: white;
  border-radius: 25px;
  transition: all 0.2s ease-in;
}
.btnGirlDocOborot:hover {
  background-color: rgb(91, 185, 187);
}
.colDocOborotStyle {
  padding: 10px 50px 10px 50px !important;
}
.nameTextGirl {
  color: #677787;
  font-size: 20px;
}
.ulDocOborot {
  padding: 0;
  color: #677787;
}
.liDocOborot::marker {
  color: rgb(91, 185, 187);
}

@media screen and (max-width: 992px) {
  .btnGirlDocOborot {
    margin-bottom: 15px;
  }
}
/* ====================Всі наступні ЛІНКИ для AboutUsPage (підсторінка) мають стиль AboutUsPage ElectroOborot========================= */

/* ================================AboutUsPage CnapPage========================= */
.nameTextShering {
  color: #677787;
  font-size: 20px;
  text-align: center;
  background-color: #f3f3f3;
  padding: 20px;
}
.cutBg {
  background-color: #f3f3f3;
  padding: 30px;
  display: flex;
  align-items: center;
  clip-path: polygon(0 25%, 5% 0, 100% 0, 100% 100%, 0 100%);
}
.textSpanCnap {
  color: #677787;
  font-size: 16px;
}
.endColStyleCnap {
  padding: 0 50px 10px 50px !important;
}

/* ====================ContactsPage===================================== */

.form_contacts {
  width: 100% !important;
}
.containerContactsStyle {
  padding: 30px 50px 10px 50px !important;
}
.App-label {
  color: #677787;
  font-weight: 500;
}
.textArea {
  height: 200px;
}
.btnSendForm {
  height: fit-content !important;
  background-color: #004c5d !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  border: none !important;
  border-radius: 25px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.btnSendForm:hover {
  background-color: rgb(91, 185, 187) !important;
}
input::placeholder {
  color: #677787 !important;
}
textarea::placeholder {
  color: #677787 !important;
}
.wrapperBtnSend {
  display: flex;
  width: 100%;
}
.wrappIcoTextAddress {
  display: flex;
  align-items: center;
}
.texPodderzhka {
  width: fit-content;
}
.MessageServ {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: orange;
  font-weight: 500;
  font-size: 18px;
}
@media screen and (max-width: 400px) {
  .containerContactsStyle {
    padding: 10px 20px 10px 20px !important;
  }
}

/* ====================CallBackModal===================================== */

.modal-header {
  background-color: #f3f3f3;
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.modal-body {
  background-color: #f3f3f3;
}
.modalColCenter {
  display: flex;
  justify-content: center;
}
.CallBackWrapperBtn2 {
  display: flex !important;
}
.btnSendModal {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.modal-backdrop.show {
  background-color: rgba(0, 0, 0) !important; /* Кольоровий фон та прозорість */
  color: rgb(0, 0, 0);
}
.modal-header .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}
.callBackTehPodd {
  display: flex;
  width: fit-content;
  margin-bottom: 0;
  padding: 6px 15px 6px 15px !important;
}
.answeMessageServ {
  color: orange;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}
.modal-footer {
  justify-content: center !important;
  background-color: #f3f3f3 !important;
}
